const isDevelopment = process.env.NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'development';
const LOCAL_API = 'http://127.0.0.1:3001/prod';
const PROD_API = 'https://api.opsecguardian.com/prod';

// In development, always use local API
const apiUrl = isDevelopment ? LOCAL_API : PROD_API;

// Debug logs
console.log('Environment Setup:', {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_NODE_ENV: process.env.REACT_APP_NODE_ENV,
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  isDevelopment,
  resolvedApiUrl: apiUrl,
  envFiles: {
    local: process.env.REACT_APP_LOCAL_ENV === 'true',
    development: process.env.REACT_APP_DEV_ENV === 'true'
  }
});

const config = {
  API_URL: apiUrl,
};

export default config;
