import axios from 'axios';
import config from '../config';

// Create Axios instance for Authentication API
console.log('Using API URL:', config.API_URL); // Debug log
const authApi = axios.create({
  baseURL: config.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add token to requests (if logged in)
authApi.interceptors.request.use((config) => {
  console.log('Request URL:', config.baseURL + config.url); // Debug log
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Handle response errors globally
authApi.interceptors.response.use(
  (response) => {
    // If the response has a body property that's a string, parse it
    if (response.data && typeof response.data.body === 'string') {
      try {
        const parsedBody = JSON.parse(response.data.body);
        response.data = parsedBody;
      } catch (error) {
        console.error('Error parsing response body:', error);
        // Keep the original response if parsing fails
        console.warn('Original response body:', response.data.body);
      }
    }
    return response;
  },
  (error) => {
    if (error.response) {
      // Only redirect on token-related 401s (not login failures)
      if (error.response.status === 401 && error.config.url !== '/auth/login') {
        // Handle unauthorized errors (e.g., expired token)
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href = '/login';
      } else {
        // Log other API errors
        console.error('API Error:', error.response.status, error.response.data);
      }
    } else if (error.request) {
      // Network error
      console.error('Network Error:', error.message);
    } else {
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default authApi;
