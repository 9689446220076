import React, { useState, useEffect } from 'react';
import Filters from './Filters';
import ScanForm from './ScanForm';
import ScanResults from './ScanResults';
import scanApi, { generateReport, getReport } from '../../api/scanApi';
import authApi from '../../api/authApi';

function ScanDashboard() {
  const [scans, setScans] = useState([]);
  const [filteredScans, setFilteredScans] = useState([]);
  const [userDetails, setUserDetails] = useState(null); // For subscription and usage details
  const [filters, setFilters] = useState({
    targetUrl: '',
    startDate: '',
    endDate: '',
    scanType: '', // Filter for scan type (immediate or recurring)
    status: ''    // Filter for scan status
  });

  useEffect(() => {
    fetchScans();
    fetchUserDetails();
  }, []);

  const fetchScans = async () => {
    try {
      const response = await scanApi.get('/scan');
      const fetchedScans = response.data.scans || [];
      setScans(fetchedScans);
      setFilteredScans(fetchedScans);
    } catch (error) {
      console.error('Error fetching scans:', error);
    }
  };

  const fetchUserDetails = async () => {
    try {
      const response = await authApi.get('/auth/user-details');
      const userData = response.data;
      console.log('User details:', userData);
      setUserDetails(userData);
    } catch (error) {
      console.error('Error fetching user details:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
    }
  };

  const handleScanCreated = (newScan) => {
    setScans((prevScans) => [newScan, ...prevScans]);
    setFilteredScans((prevScans) => [newScan, ...prevScans]);
  };

  const handleRefreshScan = async (scanId) => {
    try {
      await scanApi.post(`/scan-update`, { scan_id: scanId });
      fetchScans();
    } catch (error) {
      console.error('Error refreshing scan:', error);
    }
  };

  const handleRemoveSchedule = async (scanId) => {
    try {
      await scanApi.post('/delete-schedule', { task_id: scanId });
      fetchScans(); // Refresh the scan list
    } catch (error) {
      console.error('Error removing schedule:', error);
    }
  };

  const handleHoldSchedule = async (scanId) => {
    try {
      await scanApi.post('/hold-schedule', { task_id: scanId });
      fetchScans(); // Refresh the scan list
    } catch (error) {
      console.error('Error putting scan on hold:', error);
    }
  };

  const handleGenerateReport = async (scanId) => {
    try {
      // First try to get existing reports
      const getResponse = await getReport(scanId);
      if (getResponse.status === 200) {
        // Reports exist, open them in new tabs
        const { pdf_url, csv_url } = getResponse.data;
        window.open(pdf_url, '_blank');
        window.open(csv_url, '_blank');
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        try {
          // Reports don't exist, generate new ones
          const generateResponse = await generateReport(scanId);
          if (generateResponse.status === 200) {
            const { pdf_url, csv_url } = generateResponse.data;
            window.open(pdf_url, '_blank');
            window.open(csv_url, '_blank');
          }
        } catch (genError) {
          console.error('Error generating reports:', genError);
          alert(genError.message || 'Failed to generate reports. Please try again later.');
        }
      } else {
        console.error('Error checking for reports:', error);
        alert(error.message || 'Failed to check for existing reports. Please try again later.');
      }
    }
  };

  const handleSubscribe = async (tier) => {
    try {
      const response = await scanApi.post('/subscribe', { tier });
      if (response.status === 200) {
        fetchUserDetails(); // Refresh user details after subscription
        console.log('Subscription successful:', response.data);
      } else {
        console.error('Error subscribing:', response.data.error);
      }
    } catch (error) {
      console.error('Error subscribing:', error);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await scanApi.post('/cancel-subscription');
      if (response.status === 200) {
        fetchUserDetails(); // Refresh user details after cancellation
        console.log('Subscription canceled:', response.data);
      } else {
        console.error('Error canceling subscription:', response.data.error);
      }
    } catch (error) {
      console.error('Error canceling subscription:', error);
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilters((prev) => ({ ...prev, ...newFilters }));
  };

  const handleApplyFilters = () => {
    const { targetUrl, startDate, endDate, scanType, status } = filters;

    const filtered = scans.filter((scan) => {
      const targetMatches =
        !targetUrl || scan.target_url.toLowerCase().includes(targetUrl.toLowerCase());

      if (!scan.created_at) {
        return false;
      }

      const createdAt = new Date(scan.created_at).toLocaleDateString();
      const startDateLocale = startDate ? new Date(startDate).toLocaleDateString() : null;
      const endDateLocale = endDate ? new Date(endDate).toLocaleDateString() : null;

      const matchesStartDate = !startDate || createdAt >= startDateLocale;
      const matchesEndDate = !endDate || createdAt <= endDateLocale;

      const typeMatches =
        !scanType ||
        (scanType === 'immediate' && scan.schedule_frequency === 'immediate') ||
        (scanType === 'recurring' && scan.schedule_frequency !== 'immediate');

      const statusMatches = !status || scan.status === status;

      return targetMatches && matchesStartDate && matchesEndDate && typeMatches && statusMatches;
    });

    setFilteredScans(filtered);
  };

  const handleClearFilters = () => {
    setFilters({
      targetUrl: '',
      startDate: '',
      endDate: '',
      scanType: '',
      status: ''
    });
    setFilteredScans(scans);
  };

  // Extract unique target URLs from scans
  const uniqueTargets = Array.from(new Set(scans.map((scan) => scan.target_url)));

  return (
    <div className="container mx-auto p-6 bg-gray-50">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Security Scan Dashboard</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {/* Subscription Details Card */}
        {userDetails && (
          <div className="col-span-1 md:col-span-2 lg:col-span-3 p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-semibold text-blue-700 mb-4">Subscription Details</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
              <div className="p-4 bg-gray-50 rounded-lg">
                <p className="font-medium text-gray-600">Status</p>
                <p className="text-lg">{userDetails.subscription_status}</p>
              </div>
              <div className="p-4 bg-gray-50 rounded-lg">
                <p className="font-medium text-gray-600">Tier</p>
                <p className="text-lg">{userDetails.subscription_tier || 'None'}</p>
              </div>
              <div className="p-4 bg-gray-50 rounded-lg">
                <p className="font-medium text-gray-600">Scans Used</p>
                <p className="text-lg">{userDetails.scans_used_this_month} / {userDetails.scans_limit || '0'}</p>
              </div>
            </div>

            {userDetails.subscription_status === 'active' ? (
              <button
                onClick={handleCancelSubscription}
                className="w-full md:w-auto mt-4 bg-red-600 text-white py-2 px-6 rounded-lg hover:bg-red-700 transition-colors"
              >
                Cancel Subscription
              </button>
            ) : (
              <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                <button
                  onClick={() => handleSubscribe('tier1')}
                  className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Tier 1 (1-10 scans) $30/mo
                </button>
                <button
                  onClick={() => handleSubscribe('tier2')}
                  className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Tier 2 (11-30 scans) $60/mo
                </button>
                <button
                  onClick={() => handleSubscribe('tier3')}
                  className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Tier 3 (31-60 scans) $90/mo
                </button>
              </div>
            )}
          </div>
        )}

        {/* Scan Form Card */}
        <div className="col-span-1 md:col-span-2 lg:col-span-3 bg-white rounded-lg shadow-md">
          <div className="p-6">
            <ScanForm
              onScanCreated={handleScanCreated}
              previousTargets={uniqueTargets}
            />
          </div>
        </div>

        {/* Filters Card */}
        <div className="col-span-1 md:col-span-2 lg:col-span-3 bg-white rounded-lg shadow-md">
          <div className="p-6">
            <Filters
              filters={filters}
              onFilterChange={handleFilterChange}
              onApplyFilters={handleApplyFilters}
              onClearFilters={handleClearFilters}
              additionalFilterOptions={[
                {
                  label: 'Scan Type',
                  key: 'scanType',
                  options: [
                    { label: 'All', value: '' },
                    { label: 'Immediate', value: 'immediate' },
                    { label: 'Recurring', value: 'recurring' }
                  ]
                },
                {
                  label: 'Scan Status',
                  key: 'status',
                  options: [
                    { label: 'All', value: '' },
                    { label: 'Running', value: 'running' },
                    { label: 'Completed', value: 'completed' },
                    { label: 'On Hold', value: 'on_hold' },
                    { label: 'Failed', value: 'failed' }
                  ]
                }
              ]}
            />
          </div>
        </div>

        {/* Scan Results Card */}
        <div className="col-span-1 md:col-span-2 lg:col-span-3 bg-white rounded-lg shadow-md">
          <div className="p-6">
            <ScanResults
              scans={filteredScans}
              onRefresh={handleRefreshScan}
              onRemoveSchedule={handleRemoveSchedule}
              onHoldSchedule={handleHoldSchedule}
              onGenerateReport={handleGenerateReport}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScanDashboard;
