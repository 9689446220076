import React, { useState, useEffect } from 'react';
import Navbar from './components/common/Navbar';
import HeroSection from './components/home/HeroSection';
import ComplianceGrid from './components/home/ComplianceGrid';
import FeaturesSection from './components/home/FeaturesSection';
import ContactForm from './components/home/ContactForm';
import Footer from './components/common/Footer';
import AuthModal from './components/auth/AuthModal';
import ScanDashboard from './components/scandashboard/ScanDashboard';
import AdminDashboard from './components/admin/AdminDashboard'; 

function App() {
  const [user, setUser] = useState(null);
  const [authModalVisible, setAuthModalVisible] = useState(false);
  const [authMode, setAuthMode] = useState('login');
  const [currentPage, setCurrentPage] = useState('home'); 

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedPage = localStorage.getItem('currentPage'); 
    if (storedUser) setUser(JSON.parse(storedUser));
    if (storedPage) setCurrentPage(storedPage);
  }, []);

  const login = (userData) => {
    localStorage.setItem('user', JSON.stringify(userData));
    setUser(userData);
  };

  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('currentPage'); 
    setUser(null);
    setCurrentPage('home'); 
  };

  const openAuthModal = (mode) => {
    setAuthMode(mode);
    setAuthModalVisible(true);
  };

  const closeAuthModal = () => {
    setAuthModalVisible(false);
  };

  const navigate = (page) => {
    setCurrentPage(page);
    localStorage.setItem('currentPage', page); 
  };

  return (
    <div>
      <Navbar 
        user={user} 
        logout={logout} 
        openAuthModal={openAuthModal} 
        navigate={navigate} 
      />
      {currentPage === 'home' && (
        <>
          <HeroSection />
          <ComplianceGrid />
          <FeaturesSection />
          <ContactForm />
        </>
      )}
      {currentPage === 'scans' && user && <ScanDashboard />}
      {currentPage === 'admin' && user?.role === 'admin' && <AdminDashboard />}
      {currentPage === 'scans' && !user && (
        <div className="text-center py-8">
          <p className="text-gray-600">Please log in to access the Security Console.</p>
          <button 
            onClick={() => openAuthModal('login')} 
            className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          >
            Login
          </button>
        </div>
      )}
      {currentPage === 'admin' && (!user || user.role !== 'admin') && (
        <div className="text-center py-8">
          <p className="text-gray-600">You do not have permission to access this page.</p>
          <button 
            onClick={() => navigate('home')} 
            className="mt-4 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
          >
            Return Home
          </button>
        </div>
      )}
      <Footer />
      {authModalVisible && (
        <AuthModal
          closeModal={closeAuthModal}
          setUser={setUser}
          initialMode={authMode}
        />
      )}
    </div>
  );
}

export default App;
