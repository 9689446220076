import React, { useState } from 'react';
import SupportModal from '../modals/SupportModal'; // Ensure the import path is correct

function Navbar({ user, logout, openAuthModal, navigate }) {
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);

  const toggleSupportModal = () => {
    setIsSupportModalOpen((prev) => !prev);
  };

  return (
    <>
      {/* Navbar */}
      <nav className="bg-gray-900 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-12 h-16 items-center">
            {/* Left Section - Logo takes 4 columns */}
            <div className="col-span-4 flex items-center">
              {/* Home Button/Icon */}
              <a
                href="https://www.opsecguardian.com"
                className="text-gray-300 hover:text-white px-3 py-2 flex items-center"
              >
                {/* Replace with an SVG icon or emoji if desired */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 10l9-7 9 7M4 10h16v11H4z"
                  />
                </svg>
              </a>
              <span
                onClick={() => navigate('home')}
                className="text-2xl font-bold cursor-pointer"
              >
                OpsecGuardian
              </span>
            </div>

            {/* Right Section - Takes 8 columns */}
            <div className="col-span-8 flex justify-end items-center">
              {user ? (
                <div className="flex items-center space-x-4">
                  {/* Admin button - only show for admin users */}
                  {user.role === 'admin' && (
                    <button
                      onClick={() => navigate('admin')}
                      className="text-gray-300 hover:text-white px-3 py-2"
                    >
                      Admin Panel
                    </button>
                  )}
                  {/* Replace Contact with Support when logged in */}
                  <button
                    onClick={toggleSupportModal}
                    className="text-gray-300 hover:text-white px-3 py-2"
                  >
                    Support
                  </button>
                  <button
                    onClick={() => navigate('scans')}
                    className="text-gray-300 hover:text-white px-3 py-2"
                  >
                    Security Console
                  </button>
                  <span className="text-gray-300">
                    Welcome, <span className="font-semibold">{user.email}</span>
                  </span>
                  <button
                    onClick={logout}
                    className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700"
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <div className="flex items-center space-x-4">
                  <a href="#contact" className="text-gray-300 hover:text-white px-3 py-2">
                    Contact
                  </a>
                  <button
                    onClick={() => openAuthModal('login')}
                    className="text-gray-300 hover:text-white px-3 py-2"
                  >
                    Login
                  </button>
                  <button
                    onClick={() => openAuthModal('register')}
                    className="text-gray-300 hover:text-white px-3 py-2"
                  >
                    Register
                  </button>
                  <button
                    id="requestDemoButton"
                    onClick={() =>
                      document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })
                    }
                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                  >
                    Request Demo
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>

      {/* Support Modal */}
      {isSupportModalOpen && (
        <SupportModal isOpen={isSupportModalOpen} onClose={toggleSupportModal} user={user} />
      )}
    </>
  );
}

export default Navbar;
