import axios from 'axios';
import config from '../config';

const scanApi = axios.create({
  baseURL: config.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add Authorization header if token exists
scanApi.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Handle response errors globally
scanApi.interceptors.response.use(
  (response) => {
    // If the response has a body property that's a string, parse it
    if (response.data && typeof response.data.body === 'string') {
      try {
        const parsedBody = JSON.parse(response.data.body);
        response.data = parsedBody;
      } catch (error) {
        console.error('Error parsing response body:', error);
        // Keep the original response if parsing fails
        console.warn('Original response body:', response.data.body);
      }
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Handle unauthorized errors (e.g., logout)
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href = '/login';
      } else if (error.response.status === 400 && error.response.data?.message) {
        // Handle validation errors with custom messages
        error.message = error.response.data.message;
        if (error.response.data.status) {
          error.message += ` (Current status: ${error.response.data.status})`;
        }
      } else {
        // Log other API errors
        console.error('API Error:', error.response.status, error.response.data);
      }
    } else if (error.request) {
      // Network error
      console.error('Network Error:', error.message);
    } else {
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  }
);

// Get user email from local storage
const getUserEmail = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  return user.email;
};

// Reports API endpoints
export const generateReport = (scanId) => {
  const userEmail = getUserEmail();
  return scanApi.post('/reports/generate', { 
    scan_id: scanId,
    user_email: userEmail 
  });
};

export const getReport = (scanId) => {
  const userEmail = getUserEmail();
  return scanApi.get(`/reports/${scanId}`, {
    params: { user_email: userEmail }
  });
};

export default scanApi;
